import React, { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";

export const StcFetch = () => {
  const [sensorData, setSensorData] = useState(null);
  useEffect(() => {
    fetch(
      "https://tnmooyflntrajxp34ymztb6rq40qrmlr.lambda-url.eu-west-2.on.aws/"
    )
      .then((res) => res.json())
      .then((data) => setSensorData(data));
  }, []);

  const chart1 = {
    xAxis: {
      type: "time",
    },
    yAxis: [
      {
        name: "Pressure",
        type: "value",
        alignTicks: true,
      },
      {
        name: "Flow",
        type: "value",
        alignTicks: true,
        // splitLine: {
        //   show: false,
        // },
      },
    ],
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
    },
    series: [
      {
        name: "Pressure 1",
        data: sensorData?.Readings.map((reading) => [
          reading.timestamp,
          reading.pressure_1,
        ]),
        type: "line",
        yAxisIndex: 0,
      },
      {
        name: "Pressure 2",
        data: sensorData?.Readings.map((reading) => [
          reading.timestamp,
          reading.pressure_2,
        ]),
        type: "line",
        yAxisIndex: 0,
      },
      {
        name: "Flow",
        data: sensorData?.Readings.map((reading) => [
          reading.timestamp,
          reading.flow,
        ]),
        type: "line",
        areaStyle: {
          opacity: 0.5,
        },
        yAxisIndex: 1,
      },
    ],
  };

  const chart2 = {
    xAxis: {
      name: "Flow",
      type: "value",
    },
    yAxis: {
      name: "Pressure drop",
      type: "value",
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
    },
    series: [
      {
        data: sensorData?.Readings.map((reading) => [
          reading.flow,
          reading.pressure_1 - reading.pressure_2,
        ]),
        type: "scatter",
        yAxisIndex: 0,
      },
    ],
  };

  return (
    <div style={{ background: "white", padding: 10, minWidth: "80vw" }}>
      <ReactEcharts option={chart1} style={{ height: 300 }} />
      <br />
      <ReactEcharts option={chart2} style={{ height: 300 }} />
      {/* <pre>{JSON.stringify(sensorData?.Readings, null, 2)}</pre> */}
    </div>
  );
};
