import "../App.css";
import { StcFetch } from "../Components/StcFetch";

function StcDashboard() {
  return (
    <div className="App">
      <header className="App-header">
        <h3 style={{ marginTop: 0 }}>STC Dashboard</h3>
        <StcFetch />
      </header>
    </div>
  );
}

export default StcDashboard;
