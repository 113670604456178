import "../App.css";

function ACLMain() {
  return (
    <div className="App">
      <header className="App-header">
        <h1 className="TitleText" style={{ marginBottom: 0 }}>
          ACL
        </h1>
        <h3 style={{ marginTop: 0 }}>Asymptote Capital Limited</h3>
      </header>
    </div>
  );
}

export default ACLMain;
